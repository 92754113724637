<template>
  <div>
    <div class="relative pb-24 container mx-auto">
      <div
        class="
          min-h-screen
          container
          mx-auto
          flex flex-wrap
          items-center
          justify-center
        "
      >
        <div
          :class="showtime ? 'md:w-1/2' : 'w-full'"
          class="border-3 border-purple-500 p-8 rounded text-purple-600"
        >
          <h2 class="uppercase text-2xl font-semibold">
            Who are the Metaverse punks ?
          </h2>
          <p class="mt-4">
            MetaVerse Punks (MVPs) are unique 1 of 1 characters designed to roam
            the Metaverse with their distinctive traits.
            <br class="hidden md:block" />
            MVPs are the Genesis collection within the Average Punk Universe.
          </p>
          <p class="mt-4">
            The MVPs are the epitome of what the Metaverse should be - standing
            out from the crowd by simply being your very best self.
            <br class="hidden md:block" />
            MVPs are proud to push the boundaries, be obscure, a bit outrageous,
            and secure in their weirdness. They display to the world the beauty
            and horror of everyday life as a degen.
          </p>

          <p class="mt-4">
            Each meticulously crafted MVP begins as a full bodied 3D NFT.
            Further, each MVP will earn 10 $PZA every day, just for holding,
            whereas AVPs earn $5 PZA a day.
          </p>
        </div>
        <div
          v-if="showtime"
          class="w-full md:w-1/2 flex items-center justify-center mt-8 md:mt-0"
        >
          <div v-if="!metamaskAddress" class="text-center max-w-sm">
            <h2
              class="
                text-white
                font-semibold
                uppercase
                bg-purple-700
                p-4
                rounded-t
                text-2xl
              "
            >
              Get your MVP
            </h2>
            <h2
              class="
                text-purple-700
                font-semibold
                uppercase
                bg-white
                p-4
                rounded-b
                text-2xl
                border border-purple-700
                shadow-xl
              "
            >
              Connect your wallet
            </h2>
          </div>
          <div
            v-else-if="metamaskAddress && totalSupply == 333"
            class="text-center max-w-sm"
          >
            <h2
              class="
                text-white
                font-semibold
                uppercase
                bg-purple-700
                p-4
                rounded-t
                text-2xl
              "
            >
              SOLD OUT
            </h2>
            <h2
              class="
                text-purple-700
                font-semibold
                uppercase
                bg-white
                p-4
                rounded-b
                text-2xl
                border border-purple-700
                shadow-xl
              "
            >
              GET YOUR MVP ON Open sea
            </h2>
          </div>
          <div
            v-else-if="
              metamaskAddress &&
              (allowlistMerkleRoot === '' ||
                allowlistMerkleRoot === null ||
                allowlistMerkleRoot ===
                  '0x0000000000000000000000000000000000000000000000000000000000000000')
            "
          >
            <h2
              class="
                text-white
                font-semibold
                uppercase
                bg-purple-700
                p-4
                rounded-t
                text-2xl text-center
              "
            >
              Presale is over
            </h2>
            <h2
              class="
                text-purple-700
                font-semibold
                uppercase
                bg-white
                p-4
                rounded-b
                text-2xl text-center
                border border-purple-700
                shadow-xl
              "
            >
              Get on the list for public
            </h2>
          </div>
          <div
            v-else-if="
              metamaskAddress &&
              proof.length > 0 &&
              allowance > 0 &&
              allowance !== alreadyMinted
            "
            class="bg-purple-700 w-xs rounded"
          >
            <h2
              class="
                text-white
                font-semibold
                uppercase
                bg-purple-700
                p-4
                rounded-t
                text-2xl text-center
              "
            >
              Get your MVP
            </h2>
            <div class="flex flex-wrap justify-around">
              <p
                class="
                  bg-white
                  p-2
                  w-2/5
                  text-center
                  rounded
                  uppercase
                  text-purple-800
                  font-semibold
                "
              >
                price
              </p>
              <p
                class="
                  bg-white
                  p-2
                  w-2/5
                  text-center
                  rounded
                  uppercase
                  text-purple-800
                  font-semibold
                "
              >
                quantity
              </p>
            </div>
            <div class="flex flex-wrap justify-around mt-2">
              <p
                class="
                  bg-white
                  p-2
                  w-2/5
                  text-center
                  rounded
                  uppercase
                  text-purple-800
                  font-semibold
                  text-3xl
                "
              >
                {{ (price * count).toFixed(1) }}
                Ξ
              </p>
              <p
                :class="
                  showOptions ? 'flex justify-between items-center' : 'p-2'
                "
                class="
                  bg-white
                  w-2/5
                  text-center
                  rounded
                  uppercase
                  text-purple-800
                  font-semibold
                  text-3xl
                "
              >
                <span
                  v-if="showOptions"
                  class="cursor-pointer px-2 py-1"
                  @click="count > 1 ? count-- : ''"
                  >-</span
                >
                <span>{{ count }}</span>
                <span
                  v-if="showOptions"
                  class="cursor-pointer px-2 py-1"
                  @click="count < allowance - alreadyMinted ? count++ : ''"
                  >+</span
                >
              </p>
            </div>
            <div class="text-center mb-4">
              <button
                :disabled="fetching"
                class="
                  cursor-pointer
                  py-2
                  mx-auto
                  px-5
                  rounded-full
                  border-2 border-white
                  text-center
                  font-bold
                  text-white
                  mt-5
                "
                style="width: calc(100% - 50px)"
                @click="mint"
              >
                MINT
              </button>
            </div>
            <p class="text-white text-center text-xs my-4 uppercase">
              {{ allowance - alreadyMinted }} max per transaction
            </p>

            <!-- <p @click="showProof = !showProof" class="mb-7 mt-6 text-center text-xs text-white cursor-pointer">SHOW PARAMS</p>
            <div v-if="showProof" class="bg-gray-900 contain overflow-scroll text-white p-2">
              <p>
                payableAmount: <code class="text-green-600">{{(price * count).toFixed(1)}}</code>
              </p>
              <p>
                count: <code class="text-green-600">{{count}}</code>
              </p>
              <p>
                allowance: <code class="text-green-600">{{allowance}}</code>
              </p>
              <p>
              proof:
              <code class="text-green-600">{{proof}}</code>
              </p>
            </div> -->
          </div>

          <div
            v-else-if="
              metamaskAddress &&
              proof.length > 0 &&
              allowance > 0 &&
              allowance == alreadyMinted
            "
            class="bg-purple-700 w-xs rounded"
          >
            <h2
              class="
                text-white
                font-semibold
                uppercase
                bg-purple-700
                p-4
                rounded-t
                text-2xl text-center
              "
            >
              You already minted
            </h2>
            <h2
              class="
                text-purple-700
                font-semibold
                uppercase
                bg-white
                p-4
                rounded-b
                border border-purple-700
                shadow-xl
                text-2xl text-center
              "
            >
              Congratulations Join us in the metaverse
            </h2>
          </div>

          <div v-else>
            <h2
              class="
                text-white
                font-semibold
                uppercase
                bg-purple-700
                p-4
                rounded-t
                text-2xl text-center
              "
            >
              Minting started
            </h2>
            <h2
              class="
                text-purple-700
                font-semibold
                uppercase
                bg-white
                p-4
                rounded-b
                text-2xl text-center
                border border-purple-700
                shadow-xl
              "
            >
              Join us to Get on the list
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { ADDRESS, ABI } from "../../util/constants/mvp/contract.js";
import Web3 from "web3";
import { mapGetters } from "vuex";
import { serializeError } from "eth-rpc-errors";
import approved from "../../../approved.json";

export default {
  name: "MVP",
  components: {},
  async mounted() {
    const { ethereum } = window;
    if (!ethereum) {
      return;
    }
    window.web3 = new Web3(ethereum);
    const web3 = window.web3;
    this.web3 = web3;
    this.contract = new web3.eth.Contract(ABI, ADDRESS);
    let res = await this.contract.methods.price().call();
    this.price = web3.utils.fromWei(res, "ether");
    this.totalSupply = Number(await this.contract.methods.totalSupply().call());
  },
  data() {
    return {
      showtime: false,
      approved: approved,
      fetching: false,
      contract: null,
      web3: null,
      count: 1,
      price: 0,
      isActive: false,
      isOpen: false,
      proof: [],
      allowance: 0,
      allowlistMerkleRoot: null,
      alreadyMinted: 0,
      totalSupply: 0,

      // showProof: false
    };
  },
  methods: {
    async loadContract() {
      const { ethereum } = window;
      if (!ethereum) {
        return;
      }
      window.web3 = new Web3(ethereum);
      const web3 = window.web3;
      this.web3 = web3;
      this.contract = new web3.eth.Contract(ABI, ADDRESS);
      let res = await this.contract.methods.price().call();
      this.price = web3.utils.fromWei(res, "ether");
      this.totalSupply = Number(
        await this.contract.methods.totalSupply().call()
      );
    },
    async canClaim() {
      try {
        if (!this.contract) await this.loadContract();
        this.allowlistMerkleRoot = await this.contract.methods
          .allowlistMerkleRoot()
          .call();

        if (
          this.allowlistMerkleRoot ===
          "0x0000000000000000000000000000000000000000000000000000000000000000"
        )
          return console.log("Merkle root not set");

        let p = this.approved.find(
          (a) =>
            a.userWallet[0].toLowerCase() == this.metamaskAddress.toLowerCase()
        );

        if (p === undefined) {
          return this.showErrorMessage("You are not on the list");
        }

        this.allowance = Number(p.userWallet[1]);
        this.proof = p.proof;

        this.alreadyMinted = Number(
          await this.contract.methods
            .addressToMinted(this.metamaskAddress)
            .call()
        );
      } catch (error) {
        this.showErrorMessage(error);
      }
    },
    async postData(url = "", data = {}) {
      try {
        const response = await fetch(url, {
          method: "POST",
          body: JSON.stringify(data),
        });
        return response.json();
      } catch (error) {
        console.log(error);
      }
    },
    async mint() {
      this.fetching = true;
      try {
        const cost = this.web3.utils.toWei("0.3", "ether");

        await this.contract.methods
          .allowlistMint(this.count, this.allowance, this.proof)
          .estimateGas({
            from: this.metamaskAddress,
            value: cost,
          });

        await this.contract.methods
          .allowlistMint(this.count, this.allowance, this.proof)
          .send({
            from: this.metamaskAddress,
            value: cost,
          });

        this.showSuccess();

        this.alreadyMinted = Number(
          await this.contract.methods
            .addressToMinted(this.metamaskAddress)
            .call()
        );
        this.totalSupply = Number(
          await this.contract.methods.totalSupply().call()
        );

        this.fetching = false;
      } catch (error) {
        this.fetching = false;
        let rer = serializeError(error.message);
        let message = rer.data.originalError;
        if (message.includes("execution reverted:"))
          message = rer.data.originalError
            .split("execution reverted: ")[1]
            .split("\n")[0];
        this.totalSupply = Number(
          await this.contract.methods.totalSupply().call()
        );
        return this.showErrorMessage(message);
      }
    },
    showErrorMessage(message) {
      this.$toast.error(message, {
        position: "bottom-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.79,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    showSuccess() {
      this.$toast.success(`Success`, {
        position: "bottom-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.79,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
  computed: {
    ...mapGetters({
      metamaskAddress: "connection/metamaskAddress",
    }),
    showOptions() {
      return this.allowance - this.alreadyMinted > 1;
    },
  },
  watch: {
    metamaskAddress: {
      immediate: true,
      handler: function (val) {
        if (val) {
          this.canClaim();
        }
      },
    },
  },
};
</script>
