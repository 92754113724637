var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"relative pb-24 container mx-auto"},[_c('div',{staticClass:"min-h-screen container mx-auto flex flex-wrap items-center justify-center"},[_c('div',{staticClass:"border-3 border-purple-500 p-8 rounded text-purple-600",class:_vm.showtime ? 'md:w-1/2' : 'w-full'},[_c('h2',{staticClass:"uppercase text-2xl font-semibold"},[_vm._v(" Who are the Metaverse punks ? ")]),_vm._m(0),_vm._m(1),_c('p',{staticClass:"mt-4"},[_vm._v(" Each meticulously crafted MVP begins as a full bodied 3D NFT. Further, each MVP will earn 10 $PZA every day, just for holding, whereas AVPs earn $5 PZA a day. ")])]),(_vm.showtime)?_c('div',{staticClass:"w-full md:w-1/2 flex items-center justify-center mt-8 md:mt-0"},[(!_vm.metamaskAddress)?_c('div',{staticClass:"text-center max-w-sm"},[_c('h2',{staticClass:"text-white font-semibold uppercase bg-purple-700 p-4 rounded-t text-2xl"},[_vm._v(" Get your MVP ")]),_c('h2',{staticClass:"text-purple-700 font-semibold uppercase bg-white p-4 rounded-b text-2xl border border-purple-700 shadow-xl"},[_vm._v(" Connect your wallet ")])]):(_vm.metamaskAddress && _vm.totalSupply == 333)?_c('div',{staticClass:"text-center max-w-sm"},[_c('h2',{staticClass:"text-white font-semibold uppercase bg-purple-700 p-4 rounded-t text-2xl"},[_vm._v(" SOLD OUT ")]),_c('h2',{staticClass:"text-purple-700 font-semibold uppercase bg-white p-4 rounded-b text-2xl border border-purple-700 shadow-xl"},[_vm._v(" GET YOUR MVP ON Open sea ")])]):(
            _vm.metamaskAddress &&
            (_vm.allowlistMerkleRoot === '' ||
              _vm.allowlistMerkleRoot === null ||
              _vm.allowlistMerkleRoot ===
                '0x0000000000000000000000000000000000000000000000000000000000000000')
          )?_c('div',[_c('h2',{staticClass:"text-white font-semibold uppercase bg-purple-700 p-4 rounded-t text-2xl text-center"},[_vm._v(" Presale is over ")]),_c('h2',{staticClass:"text-purple-700 font-semibold uppercase bg-white p-4 rounded-b text-2xl text-center border border-purple-700 shadow-xl"},[_vm._v(" Get on the list for public ")])]):(
            _vm.metamaskAddress &&
            _vm.proof.length > 0 &&
            _vm.allowance > 0 &&
            _vm.allowance !== _vm.alreadyMinted
          )?_c('div',{staticClass:"bg-purple-700 w-xs rounded"},[_c('h2',{staticClass:"text-white font-semibold uppercase bg-purple-700 p-4 rounded-t text-2xl text-center"},[_vm._v(" Get your MVP ")]),_vm._m(2),_c('div',{staticClass:"flex flex-wrap justify-around mt-2"},[_c('p',{staticClass:"bg-white p-2 w-2/5 text-center rounded uppercase text-purple-800 font-semibold text-3xl"},[_vm._v(" "+_vm._s((_vm.price * _vm.count).toFixed(1))+" Ξ ")]),_c('p',{staticClass:"bg-white w-2/5 text-center rounded uppercase text-purple-800 font-semibold text-3xl",class:_vm.showOptions ? 'flex justify-between items-center' : 'p-2'},[(_vm.showOptions)?_c('span',{staticClass:"cursor-pointer px-2 py-1",on:{"click":function($event){_vm.count > 1 ? _vm.count-- : ''}}},[_vm._v("-")]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.count))]),(_vm.showOptions)?_c('span',{staticClass:"cursor-pointer px-2 py-1",on:{"click":function($event){_vm.count < _vm.allowance - _vm.alreadyMinted ? _vm.count++ : ''}}},[_vm._v("+")]):_vm._e()])]),_c('div',{staticClass:"text-center mb-4"},[_c('button',{staticClass:"cursor-pointer py-2 mx-auto px-5 rounded-full border-2 border-white text-center font-bold text-white mt-5",staticStyle:{"width":"calc(100% - 50px)"},attrs:{"disabled":_vm.fetching},on:{"click":_vm.mint}},[_vm._v(" MINT ")])]),_c('p',{staticClass:"text-white text-center text-xs my-4 uppercase"},[_vm._v(" "+_vm._s(_vm.allowance - _vm.alreadyMinted)+" max per transaction ")])]):(
            _vm.metamaskAddress &&
            _vm.proof.length > 0 &&
            _vm.allowance > 0 &&
            _vm.allowance == _vm.alreadyMinted
          )?_c('div',{staticClass:"bg-purple-700 w-xs rounded"},[_c('h2',{staticClass:"text-white font-semibold uppercase bg-purple-700 p-4 rounded-t text-2xl text-center"},[_vm._v(" You already minted ")]),_c('h2',{staticClass:"text-purple-700 font-semibold uppercase bg-white p-4 rounded-b border border-purple-700 shadow-xl text-2xl text-center"},[_vm._v(" Congratulations Join us in the metaverse ")])]):_c('div',[_c('h2',{staticClass:"text-white font-semibold uppercase bg-purple-700 p-4 rounded-t text-2xl text-center"},[_vm._v(" Minting started ")]),_c('h2',{staticClass:"text-purple-700 font-semibold uppercase bg-white p-4 rounded-b text-2xl text-center border border-purple-700 shadow-xl"},[_vm._v(" Join us to Get on the list ")])])]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"mt-4"},[_vm._v(" MetaVerse Punks (MVPs) are unique 1 of 1 characters designed to roam the Metaverse with their distinctive traits. "),_c('br',{staticClass:"hidden md:block"}),_vm._v(" MVPs are the Genesis collection within the Average Punk Universe. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"mt-4"},[_vm._v(" The MVPs are the epitome of what the Metaverse should be - standing out from the crowd by simply being your very best self. "),_c('br',{staticClass:"hidden md:block"}),_vm._v(" MVPs are proud to push the boundaries, be obscure, a bit outrageous, and secure in their weirdness. They display to the world the beauty and horror of everyday life as a degen. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-wrap justify-around"},[_c('p',{staticClass:"bg-white p-2 w-2/5 text-center rounded uppercase text-purple-800 font-semibold"},[_vm._v(" price ")]),_c('p',{staticClass:"bg-white p-2 w-2/5 text-center rounded uppercase text-purple-800 font-semibold"},[_vm._v(" quantity ")])])
}]

export { render, staticRenderFns }